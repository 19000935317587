import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { INVALID_GUESSES } from '../constants/invalidGuesses'
import dayJson from '../days.json'

export const isWordInWordList = (word: string) => {
  return (
    (WORDS.includes(word.toLowerCase()) ||
    VALID_GUESSES.includes(word.toLowerCase())) &&
    (!INVALID_GUESSES.includes(word.toLowerCase()))
  )
}

export const getCurrentWord = () => {
  return solution
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const showDate = (withHyphens: boolean) => {

  var wordKey = new Date().toJSON().slice(0,10).replace(/-/g,'').toString();

  var currentPath = window.location.pathname.replace('/', '');
  if ((dayJson as any)[currentPath]) {
      wordKey = currentPath;
  }

  if (withHyphens) {
    return wordKey.substring(0, 4) + '-' + wordKey.substring(4, 6) + '-' + wordKey.substring(6, 8)
  } else {
    return wordKey
  }
}

export const getTodayDate = () => {

  let date = new Date()

  return date.toJSON().slice(0,10).replace(/-/g,'').toString();
}

export const getNextDate = (fromDate: string) => {

  let date = new Date(parseInt(fromDate.substring(0, 4)), parseInt(fromDate.substring(4, 6)) - 1, parseInt(fromDate.substring(6, 8)))
  date.setDate(date.getDate() + 1)

  return date.toJSON().slice(0,10).replace(/-/g,'').toString();
}

export const getPreviousDate = (fromDate: string) => {

  let date = new Date(parseInt(fromDate.substring(0, 4)), parseInt(fromDate.substring(4, 6)) - 1, parseInt(fromDate.substring(6, 8)))
  date.setDate(date.getDate() - 1)

  return date.toJSON().slice(0,10).replace(/-/g,'').toString();
}

export const getIsToday = () => {

  var wordKey = new Date().toJSON().slice(0,10).replace(/-/g,'').toString();

  var currentPath = window.location.pathname.replace('/', '');
  if (currentPath === '') {
    return true;
  }
  if ((dayJson as any)[currentPath]) {
      if (wordKey === currentPath) {
        return true
      }
  }

  return false
}

export const getWordOfDay = () => {

  var wordKey = new Date().toJSON().slice(0,10).replace(/-/g,'').toString();

  var currentPath = window.location.pathname.replace('/', '');
  if ((currentPath !== "") && (currentPath.substring(0, 6) !== 'static')) {
    if (currentPath > wordKey) {
        window.location.href = '/';
    } else if (parseInt(currentPath) < 20220209) {
        window.location.href = '/20220209';
    } else if ((dayJson as any)[currentPath]) {
      wordKey = currentPath;
    } else {
      window.location.href = '/';
    }
  }

  var wordIndex = (dayJson as any)[wordKey] + ''; // Force to decent string


  // January 1, 2022 Game Epoch
  const epochMs = 1643155200000
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: wordIndex.toUpperCase(),
    solutionIndex: showDate(true),
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()
